import { createApp } from "vue";
// import { createRouter, createWebHistory} from '.vue-router'
import { createGtm } from "@gtm-support/vue-gtm";
import App from "./App.vue";
import "./index.css";
import VueGtag from "vue-gtag-next";
// import router from 'vue-router';

const app = createApp(App);

app.use(VueGtag, {
  property: {
    id: "G-6ZL72XRQM6",
    params: {
        'debug_mode' : true
    }
  },
});

app.use(createGtm({
    id: 'GTM-N58GGHZ',
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: true,
    loadScript: true,
    // vueRouter: router,
    trackOnNextTick: false
}));

app.mount("#app");
