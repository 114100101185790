<template>
  <section class="mx-auto px-10 py-20 flex flex-col md:flex-row xl:max-w-7xl items-center justify-center">
    <img
      class="w-96 lg:w-2/5 mb-4 p-6 object-cover object-center"
      src="../assets/pain.png"
      alt="Information overload."
    />
    <div class="lg:w-3/5 flex flex-wrap justify-start md:justify-center">
      <div class="md:w-52 lg:w-56 xl:w-60 m-6 xl:mx-8" v-for="painpoint in painpoints" :key="painpoint.id">
        <h2 class="text-oc-blue font-black text-xl">{{ painpoint.title }}</h2>
        <p class="mt-2 text-gray-700">{{ painpoint.description }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      painpoints: [
        {
          id: 1,
          title: "新闻太多！",
          description:
            "每天的新闻鱼龙混杂、真假难辨，花那么多时间读新闻还不如不看？",
        },
        {
          id: 2,
          title: "读英文太费劲！",
          description: "英文新闻读起来太费劲，半天读不完一篇，读完还不太懂。",
        },
        {
          id: 3,
          title: "千篇一律的新闻？",
          description: "看惯了千篇一律的报道，想要听到不一样的声音？",
        },
        {
          id: 4,
          title: "怕被带节奏？",
          description:
            "正能量不能当饭吃，锻炼自己独立和批判思考的能力，再也不怕被带节奏。",
        },
      ],
    };
  },
};
</script>