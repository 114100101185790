<template>
  <section class="px-10 py-20 mb-8">
    <h1 class="mt-8 text-center text-2xl md:text-3xl font-semibold text-gray-900">关于我们</h1>
    <p class="mx-auto mt-6 md:mt-8 sm:w-2/3 lg:w-1/2 xl:w-2/5 text-gray-700 text-center leading-7">
      我们致力于传播新闻自由、言论自由和信息公开的价值，鼓励独立思考与辩证思考的能力，力求为中文网络社会带来更加理性、客观的声音。
    </p>
    <p class="mx-auto mt-4 sm:w-2/3 lg:w-1/2 xl:w-2/5 text-gray-700 text-center leading-7">
      我们来自中国大陆，香港和台湾，我们的组织完全独立并由志愿者运作和管理。
    </p>
  </section>
</template>