<template>
  <!-- SnapWidget -->
  <section class="bg-gray-100 pb-20 px-10">
    <h2
      class="pt-16 text-center text-2xl md:text-3xl font-semibold text-gray-900"
    >
      最新推文
    </h2>
    <p class="mx-auto mt-3 md:mt-4 sm:w-2/3 mb-10 lg:w-1/2 xl:w-2/5 text-gray-700 text-center leading-7">
      以下是我们在 <a href="https://www.instagram.com/outsight_china/" target="_blank" class="instagram"><span class="font-medium text-blue-600 hover:text-blue-800">Instagram</span></a> 上的最新推文，我们也同时在 <a
        href="https://t.me/OutsightChina"
        target="_blank" class="telegram"
        ><span class="font-medium text-blue-600 hover:text-blue-800"
          >Telegram</span
        ></a
      >
      上进行推送，欢迎关注我们！
    </p>
    <iframe
      src="https://snapwidget.com/embed/940200"
      id="snap-widget"
      class="snapwidget-widget mx-auto w-full sm:w-1/2 xl:w-2/5 h-112 lg:h-160 border-none overflow-hidden"
      :class="{height: returnedHeight}"
      allowtransparency="true"
      frameborder="0"
      scrolling="yes"
    ></iframe>
  </section>
</template>