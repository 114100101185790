<template>
  <the-navbar></the-navbar>
  <the-header></the-header>
  <latest-post></latest-post>
  <the-painpoints></the-painpoints>
  <the-benefits></the-benefits>
  <subscribe-newsletter></subscribe-newsletter>
  <the-about-us></the-about-us>
  <the-contact-us></the-contact-us>
  <the-footer></the-footer>
</template>


<script>
import TheNavbar from './UI/TheNavbar.vue'
import TheHeader from './UI/TheHeader.vue'
import ThePainpoints from './UI/ThePainpoints.vue'
import TheBenefits from './UI/TheBenefits.vue'
import TheAboutUs from './UI/TheAboutUs.vue'
import TheContactUs from './UI/TheContactUs.vue'
import TheFooter from './UI/TheFooter.vue'
import LatestPost from './UI/LatestPost.vue'
import SubscribeNewsletter from './UI/SubscribeNewsletter.vue'

export default {
  components: {
    TheNavbar,
    TheHeader,
    ThePainpoints,
    TheBenefits,
    TheAboutUs,
    TheContactUs,
    TheFooter,
    LatestPost,
    SubscribeNewsletter
  }
}
</script>
