<template>
  <section class="px-10 py-20 bg-gray-100">
    <h1 class="mt-6 text-center text-2xl md:text-3xl font-semibold text-gray-900">联系我们</h1>
    <p class="mx-auto mt-6 mb-8 md:mt-8 sm:w-2/3 lg:w-1/2 xl:w-2/5 text-gray-700 text-center leading-7">
      如有任何意见和反馈，欢迎<a href="mailto:outsightchina@gmail.com"><span class="font-medium text-blue-600 hover:text-blue-800">邮件</span></a>和IG上给我们留言，或是在看鉴中国的<a href="https://t.me/joinchat/SE9X60w0JEL2_aBh" target="_blank"><span class="font-medium text-blue-600 hover:text-blue-800">Telegram读者群</span></a>和我们互动。
    </p>
    <!-- <a class="group flex items-center mt-8 mb-6 justify-center" href="#">
      <svg class="mr-2 text-blue-600 group-hover:text-blue-800 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"/></svg>
      <p class="text-center font-medium text-blue-600 group-hover:text-blue-800">outsightchina@gmail.com</p>
    </a> -->
  </section>
</template>