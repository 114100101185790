<template>
  <section class="px-10 py-20 mb-8 bg-gray-100" id="subscribe">
    <h1 class="mt-8 text-center text-2xl md:text-3xl font-semibold text-gray-900">
      订阅周报
    </h1>
    <p class="mx-auto mt-6 md:mt-8 sm:w-2/3 lg:w-1/2 xl:w-2/5 text-gray-700 text-center leading-7">
      不在社交媒体？没有梯子？
    </p>
    <p class="mx-auto mt-2 sm:w-2/3 lg:w-1/2 xl:w-2/5 text-gray-700 text-center leading-7">
      欢迎订阅我们每周一期的邮件周报，每周你会收到过去七天的时事整理，不会错过热点新闻的中外媒体观点。<a href="https://us4.campaign-archive.com/home/?u=76a9486d851a664f357e250ab&id=ad48e23c1e" target="_blank"><span class="newsletter-previous font-medium text-blue-600 hover:text-blue-800">阅读往期周报</span></a>
    </p>
    <mailchimp-subscribe></mailchimp-subscribe>
  </section>
</template>

<script>
// import ClassicSubscribe from '../components/ClassicSubscribe.vue'
import MailchimpSubscribe from '../components/MailchimpSubscribe.vue'

export default {
  components: {
    // ClassicSubscribe,
    MailchimpSubscribe
  },
};
</script>