<template>
  <section class="mx-auto md:mt-6 px-10 py-20 bg-gray-700">
    <h2 class="mt-10 text-center text-2xl md:text-3xl font-semibold text-white">看鉴中国可以帮你</h2>
    <div class="flex flex-wrap mx-auto xl:max-w-7xl mt-10 md:mt-20">
        <div class="w-full sm:w-1/2 lg:w-1/4 mb-14">
            <img class="w-32 mx-auto" src="../assets/benefit-1.png" alt="only one">
            <h3 class="mt-5 font-bold text-xl text-center text-gray-100">每天一则</h3>
            <p class="mt-4 mx-auto w-56 sm:w-44 md:w-56 lg:px-3 xl:px-1 text-gray-300">每天选一则关于中国、最重要的时事新闻，绝不会让你眼花、刷你屏。</p>
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/4 mb-14">
            <img class="w-32 mx-auto" src="../assets/benefit-2.png" alt="contrast news">
            <h3 class="mt-5 font-bold text-xl text-center text-gray-100">对比报道</h3>
            <p class="mt-4 mx-auto w-56 sm:w-44 md:w-56 lg:px-3 xl:px-1 text-gray-300">针对同一则新闻，我们选取来自中外媒体不同的报道，客观对比呈现它们的不同。</p>
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/4 mb-14">
            <img class="w-32 mx-auto" src="../assets/benefit-3.png" alt="reliable source">
            <h3 class="mt-5 font-bold text-xl text-center text-gray-100">来源可信</h3>
            <p class="mt-4 mx-auto w-56 sm:w-44 md:w-56 lg:px-3 xl:px-1 text-gray-300">拒绝小道消息，拒绝主观意识强烈的报道，只选取来源真实可信的中外大媒体。</p>
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/4 mb-14">
            <img class="w-32 mx-auto" src="../assets/benefit-4.png" alt="weekly newsletter">
            <h3 class="mt-5 font-bold text-xl text-center text-gray-100">一周回顾</h3>
            <p class="mt-4 mx-auto w-56 sm:w-44 md:w-56 lg:px-3 xl:px-1 text-gray-300">不在社交媒体？<a href="#subscribe"><span class="underline text-white">订阅我们的周报</span></a>，一网打尽一周发生的大小事。</p>
        </div>
    </div>
  </section>
</template>