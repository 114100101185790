<template>
  <div class="w-full fixed flex justify-between items-center bg-nav-blue py-3 px-8 md:px-16">
    <a href="https://www.outsightchina.com"><img class="w-32" src="../assets/nav-logo.svg" alt="outsight logo" /></a>
    <div class="flex items-center" :class="{hidden: scrollPosition < 520}">
        <a href="https://www.instagram.com/outsight_china/" target="_blank" class="instagram"><img src="../assets/nav-ins.png" alt="instagram logo" class="w-8 mx-2"></a>
        <a href="https://t.me/OutsightChina" target="_blank" class="telegram"><img src="../assets/nav-telegram.png" alt="telegram logo" class="w-8 mx-2"></a>
        <button class="newsletter-anchor hidden sm:block px-3 h-8 rounded-full bg-white hover:bg-gray-200 text-oc-blue text-sm font-medium mx-2 focus:outline-none focus:ring" @click="scrollToElement">订阅周报</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    //   console.log(this.scrollPosition);
    },
    scrollToElement(){
      const el = document.getElementById("subscribe");
      el.scrollIntoView({behavior: "smooth"});
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>
