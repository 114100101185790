<template>
  <div class="bg-hero-img bg-center bg-cover py-20 md:py-28 px-10 lg:px-40">
    <h1 class="mt-20 md:mt-16 text-3xl md:text-4xl text-center font-black text-white">
      一个健康的社会，不该只有一种声音。
    </h1>
    <h2 class="mt-10 mx-auto sm:max-w-lg md:max-w-xl text-lg md:text-xl text-center font-normal leading-8 md:leading-9 text-gray-300">
      每天聚焦一则关于中国的新闻事件，带你客观对比来自中外不同媒体多元的、不一样的观点。
    </h2>
    <div class="mt-24 flex justify-center">
      <a href="https://www.instagram.com/outsight_china/" target="_blank" class="instagram">
        <div class="flex items-center sm:px-8 group">
          <img
            class="w-10 mx-2 sm:mx-0"
            src="../assets/instagram.png"
            alt="instagram logo"
          />
          <p class="hidden sm:block ml-3 text-gray-100 group-hover:text-gray-300">Instagram</p>
        </div>
      </a>
      <a href="https://t.me/OutsightChina" target="_blank" class="telegram">
        <div class="flex items-center sm:px-8 group">
          <img
            class="w-10 mx-2 sm:mx-0"
            src="../assets/telegram.png"
            alt="instagram logo"
          />
          <p class="hidden sm:block ml-3 text-gray-100 group-hover:text-gray-300">Telegram</p>
        </div>
      </a>
      <div @click="scrollToElement">
        <button class="newsletter-anchor w-36 mx-2 sm:mx-8 px-8 py-1 rounded-full bg-white text-gray-700 font-bold text-lg hover:bg-gray-200 focus:outline-none focus:ring">
        订阅周报
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    scrollToElement(){
      const el = document.getElementById("subscribe");
      el.scrollIntoView({behavior: "smooth"});
    },
  }
}
</script>