<template>
    <!-- Begin Mailchimp Signup Form -->
<form action="https://gmail.us4.list-manage.com/subscribe/post?u=76a9486d851a664f357e250ab&amp;id=ad48e23c1e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll" class="my-8 flex items-center justify-center">
        <div class="mc-field-group">
            <input type="email" class="rounded-full border-2 px-6 py-2 mr-3 focus:outline-none focus:ring" value="" name="EMAIL" id="mce-EMAIL" placeholder="输入电子邮件地址">
        </div>
        <div id="mce-responses" class="clear">
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
        </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_76a9486d851a664f357e250ab_ad48e23c1e" tabindex="-1" value=""></div>
        <div class="clear">
            <input type="submit" value="订阅" class="newsletter-subscribe rounded-full px-6 py-2 cursor-pointer text-white bg-oc-blue hover:bg-blue-800" name="subscribe" id="mc-embedded-subscribe">
        </div>
    </div>
</form>

<!--End mc_embed_signup-->
</template>